import { formValidation } from './components/bs-form-validation';
import { toastsList } from './components/bs-toasts-list';
// import { formSubmit } from "./components/form-submit";
// import { popoverLists } from './components/bs-popover-lists';
// import { slickSlider } from "./components/slick-slider";
// import { svgSprite } from "./components/svg-sprite";
import { consoleMessage } from './components/console-message';
import { dism4Alert } from './components/dism4-alert';
import { drop4 } from './components/drop4';
import { externalLinks } from './components/external-links';
import { generalFunctions } from './components/general-functions';
import { gridSlider } from './components/grid-slider';
import { hcaptchaCallback } from './components/hcaptcha-callback';
import { htmxFunctions } from './components/htmx';
import { languageSelector } from './components/language-selector';
import { oscarFunctions } from './components/oscar-functions';
import { preloadFunction } from './components/preload';
import { recommenderFunctions } from './components/recommender-functions';
import { searchInputFocus } from './components/search-modal';
import { starRating } from './components/star-rating';
import { scrollShadow } from './components/sticky-header-scroll-shadow';
import { umamiEvents } from './components/umami-events';

// Media Chrome web component https://www.media-chrome.org
import 'media-chrome';
// https://www.media-chrome.org/docs/en/media-elements/youtube-video
import 'youtube-video-element';

// run these first
generalFunctions();
// svgSprite();

document.addEventListener('DOMContentLoaded', () => {
  /* eslint-disable no-new, no-restricted-syntax */
  consoleMessage();
  dism4Alert();
  drop4();
  externalLinks();
  formValidation();
  gridSlider();
  hcaptchaCallback();
  htmxFunctions();
  languageSelector();
  oscarFunctions();
  preloadFunction();
  recommenderFunctions();
  scrollShadow();
  searchInputFocus();
  starRating();
  toastsList();
  umamiEvents();
});

// re-init after HTMX swap
document.addEventListener('htmx:afterSwap', (evt) => {
  dism4Alert(evt);
  drop4(evt);
  externalLinks(evt);
  gridSlider(evt);
  languageSelector(evt);
  oscarFunctions(evt);
  recommenderFunctions(evt);
  scrollShadow(evt);
  starRating(evt);
  umamiEvents(evt);
});
