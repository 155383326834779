// #750 lang path neu befüllen und Formular abschicken
export function languageSelector() {
  const langForm = document.getElementById('language_form');
  const langList = document.getElementById('language_list');

  const urlInput = document.getElementById('id_neut_lang');
  const lang = '/{{ request.LANGUAGE_CODE }}/';

  if (langForm != null) {
    langList.addEventListener('change', () => {
      const url = new URL(window.location.href);

      urlInput.value = url.pathname.replace(lang, '/');
      langForm.submit();
    });
  }
}
