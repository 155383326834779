export function umamiEvents() {
  // Login button
  const loginButton = document.getElementById('login_submit');
  // Registration button
  const regButton = document.getElementById('registration_submit');
  // Categories: Best Sellers, Low-Priced, etc.
  const categories = document.querySelectorAll('.categories-nav > a');
  // Subcategories: Fiction, Games, Reference etc.
  const subcategories = document.querySelectorAll('.subcategories-nav > ul > li > a');
  // Logo -> Home
  const logo = document.querySelector('.site__name > a');
  // tracking
  if (loginButton != null) {
    loginButton.onclick = () => umami.track('Login');
  }
  if (regButton != null) {
    regButton.onclick = () => umami.track('Registration');
  }
  if (categories != null) {
    for (let i = 0; i < categories.length; i++) {
      categories[i].addEventListener('click', () => {
        umami.track('Home Categories');
      });
    }
  }
  if (subcategories != null) {
    for (let i = 0; i < subcategories.length; i++) {
      subcategories[i].addEventListener('click', () => {
        umami.track('Home Subcategories');
      });
    }
  }
  if (logo != null) {
    logo.onclick = () => umami.track('Logo -> Home');
  }
}
