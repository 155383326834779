export function recommenderFunctions() {
  // recommender 'collect'
  const coll = document.querySelectorAll('.rec');
  if (coll != null) {
    for (let i = 0; i < coll.length; i++) {
      coll[i].addEventListener('click', function () {
        console.log(`Collect: ${[this.dataset.recPart, this.dataset.recLoad]}`);
        collector.collect(this.dataset.recPart, this.dataset.recLoad);
      });
    }
  }

  // recommender 'collectSubmit'
  const collSub = document.querySelectorAll('.rec__submit');
  if (collSub != null) {
    for (let i = 0; i < collSub.length; i++) {
      collSub[i].addEventListener('submit', function (event) {
        // event.preventDefault();
        console.log(`Submit: ${[this.dataset.recFunction, this.dataset.recPart, this.dataset.recLoad]}`);
        collector.collectSubmit(this.dataset.recFunction, this.dataset.recPart, this.dataset.recLoad);
      });
    }
  }
}
