export function starRating() {
  const starEls = document.querySelectorAll('.star');
  const scoreValue = document.getElementById('id_score');

  // console.info(`${starEls.length} star SVGs found`)
  starEls.forEach((star) => {
    star.addEventListener('click', (e) => {
      const starEl = e.currentTarget;

      // console.info(starEl.parentNode.dataset.stars + ", " + starEl.dataset.rating);

      // set parent's value for display purposes
      starEl.parentNode.setAttribute('data-stars', starEl.dataset.rating);
      // set form option value
      scoreValue.value = starEl.dataset.rating;
    });
  });
}
